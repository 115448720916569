import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
    transform(value: any): SafeHtml {
  
      if (!value) {
        return this.sanitizer.bypassSecurityTrustHtml('');
      }
  
      if (typeof value === 'number') {
        return this.sanitizer.bypassSecurityTrustHtml(value.toString());
      }
  
      if (typeof value !== 'string') {
        return this.sanitizer.bypassSecurityTrustHtml('');
      }
  
      const regex = /(\d)\/(\d)/g;

      if (!regex.test(value)) {
        value = value.replace(/\//g, '<br />');
      }
  
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}