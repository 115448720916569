import { ModalGroupVolunteersComponent } from './../../shared/modalGroupVolunteers/modalGroupVolunteers.component';

import {
  throwError as observableThrowError,
  Observable,
  Subject
} from 'rxjs';

import { catchError, finalize, tap, takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import * as _ from 'lodash';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
  DNATypes,
  Hub,
  HubById,
  Hubs,
  Panelist,
  TableHeader,
  Volunteers,
  User,
  Result,
  PanelistTimeline,
  TimelineTypesDB,
  Workspace
} from '../../types';
import { CampaignService } from '../../campaigns/campaigns.service';
import {
  DNATranslateService
} from '../../shared/services/translate.service';
import {
  EditGroupHorsArcsRowComponent
} from './edit-group-horsarcs-row/edit-group-horsarcs-row.component';
import {
  ErrorManagerService
} from '../../shared/services/errorManager.service';
import { FilterService } from '../../shared/services/filter.service';
import { ReferenceTypeService } from '../../shared/services/reference-type.service';
import { UserService } from '../../shared/services/user.service';
import {
  UtilService
} from '../../shared/services/util.service';
import {
  VolunteerService
} from '../volunteers.service';
import { ApplicationInsightsService } from '../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-volunteers',
  templateUrl: './volunteers.component.html',
  styleUrls: ['./volunteers.component.less']
})
export class VolunteersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(EditGroupHorsArcsRowComponent) editGroupHorsArcsRowComponent: EditGroupHorsArcsRowComponent;

  countries: Hubs[];
  filter: any;
  filterHA: any;
  filterArcs: any;
  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };
  panelistsData: PanelistTimeline[] = [];
  groupPanelistsHAData: any[] = [];
  panelistsArcsData: any[] = [];
  studyArcsData: any[] = [];
  isPanelistsArcsDataEmpty: boolean = false;
  showSpinner: boolean = false;
  timelineTypes: string[];
  typeFiche: string;
  user: User;
  volunteers: Volunteers[];
  idVolunteer: string = '';
  numberVolunteerHorsArcs: string = '';
  arcsIncorrect: boolean = false;
  horsArcsIncorrect: boolean = false;
  arcsLoading: boolean;
  horsArcsLoading: boolean;
  hub: HubById;
  hubHA: HubById;
  country: Hub;
  countryHA: Hub;
  groupVolunteerHA: string[] = [];
  formHorsArcsGroupHA: string = "";
  actualGroupVolunteer: string = "";
  groupVolunteersHA: string[] = [];
  currentWorkspace: Workspace = new Workspace();
  studyNumberFilter: string = "";
  initTime = performance.now();

  tableHeaders$: Observable<TableHeader[]>;
  panelistTableHeaders$: Observable<TableHeader[]>;
  groupPanelistHATableHeaders$: Observable<TableHeader[]>;
  panelistArcsTableHeaders$: Observable<TableHeader[]>;
  isCollapsedSearchFilters: boolean = false;
  isCollapsedSearchFiltersArcs: boolean = true;
  isCollapsedSearchFiltersHA: boolean = true;
  totalPanelists: number = 0;
  totalPanelistsHA: number = 0;
  totalArcsPanelists: number = 0;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public campaignService: CampaignService,
    private errorManager: ErrorManagerService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private referenceTypeService: ReferenceTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private translateService: DNATranslateService,
    private userService: UserService,
    private utilService: UtilService,
    private volunteerService: VolunteerService
  ) { }

  ngOnInit() {
    this.panelistTableHeaders$ = this.utilService.createHeaders(DNATypes.Panelists);
    this.groupPanelistHATableHeaders$ = this.utilService.createHeaders(DNATypes.GroupPanelistsHA);
    this.panelistArcsTableHeaders$ = this.utilService.createHeaders(DNATypes.PanelistsArcs);
    this.user = this.userService.getUser();
    this.filter = _.cloneDeep(this.filterService.getFilter(this.userService.getUserHub(this.user.hub)).panelists);
    this.filterHA = _.cloneDeep(this.filterService.getFilter(this.userService.getUserHub(this.user.hub)).panelists);
    this.filterArcs = _.cloneDeep(this.filterService.getFilter(this.userService.getUserHub(this.user.hub)).panelists);

    this.countries = this.referenceTypeService.getHubs();
    this.timelineTypes = this.referenceTypeService.getTimelineTypes();
    this.typeFiche = this.timelineTypes[0];
    this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Volunteers);

    this.hub = this.userService.getUserHubById();
    this.hubHA = this.userService.getUserHubById();
    this.country = this.referenceTypeService.getHubFromHubById(this.hub);
    this.countryHA = this.referenceTypeService.getHubFromHubById(this.hubHA);

    this.translateService.onLangChange().pipe(
      tap(translation => this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Volunteers)),
      takeUntil(this.destroy$))
      .subscribe();

    this.getValuesForFormHA(this.hubHA, this.groupVolunteerHA, this.formHorsArcsGroupHA, this.groupVolunteersHA);

    this.currentWorkspace = this.userService.getUser().currentWorkspace;
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Volunteers Page', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  deleteVolunteer(volunteer: Volunteers) {
    this.showSpinner = true;
    return this.volunteerService.deleteVolunteer(volunteer.id).pipe(
      finalize(() => this.showSpinner = false),
      catchError(err => {
        this.errorManager.catchError(err)
        return observableThrowError(err);
      }));
  }

  /**
  * Ouvre la page du volontaire si celui-ci existe déjà
  * sinon créé le volontaire avant d'ouvrir la page de celui-ci
  * @param volunteerId numéro arc saisi
  * @param arcsSystem hub sélectionné dans la liste déroulante
  */
  getArcsVolunteer(volunteerId: string, hub: HubById): void {
    this.arcsIncorrect = false;
    this.arcsLoading = true;
    this.volunteerService.getPanelistById(volunteerId, hub).pipe(
      tap(volunteer => {
        if (volunteer && volunteer.panelistNumber) {
          this.volunteerService.setPanelist(volunteer);
          this.volunteerService.setPanelistIsLoaded(true);
          this.router.navigate(['volunteers', volunteer.panelistNumber, volunteer.hub, 'generalInformation'])
        } else {
          this.arcsIncorrect = true;
        }
      }),
      finalize(() => {
        this.arcsLoading = false;
      }), catchError(err => {
        this.arcsIncorrect = true;
        return observableThrowError(err);
      }) ).subscribe();
  }

  getHorsArcsVolunteer(numberVolunteer: string, hub: HubById, groupVolunteer:any[] = [], formHorsArcsGroup:string = ""): void {
    this.horsArcsIncorrect = false;
    this.horsArcsLoading = true;
    this.actualGroupVolunteer = "";

    groupVolunteer[0] ? this.actualGroupVolunteer = groupVolunteer[0].groupName : this.actualGroupVolunteer = formHorsArcsGroup
    this.volunteerService.getPanelistById(`${this.actualGroupVolunteer}_${numberVolunteer}`, hub, 'horsarcs').pipe(
      tap(volunteer => {
        if (volunteer && volunteer.panelistNumber) {
          this.volunteerService.setPanelist(volunteer);
          this.volunteerService.setPanelistIsLoaded(true);
          this.router.navigate(['volunteers', volunteer.panelistNumber, volunteer.hub, 'generalInformation'])
        } else {
          this.horsArcsIncorrect = true;
        }
      }),
      finalize(() => {
        this.horsArcsLoading = false;
      }),catchError(err => {
        this.horsArcsIncorrect = true;
        return observableThrowError(err);
      })).subscribe();
  }

  getValuesForFormHA(hub: HubById, groupVolunteer:string[], formHorsArcsGroup:string, groupVolunteers: string[])
  {
    groupVolunteer = [];
    formHorsArcsGroup = "";

    this.volunteerService.getVolunteerGroupShort(hub).subscribe({
      next: (result) => {
        const list = result.list;
        this.groupVolunteersHA = this.resultToString(list);
      }
    });
    return groupVolunteers;
  }

  resultToString(valueList):string[]
  {
    let returnValue: string[] = [];

    for (let value of valueList)
    {
       if (value.groupName)
         returnValue.push(value.groupName);
    }

    return returnValue;
  }

  goToFiche(panel: PanelistTimeline) {
    switch (panel.subclass) {
      case TimelineTypesDB.FICHE_CARACTERISATION:
        {
          this.router.navigate(['volunteers', panel.panelistNumber, panel.hub, 'ficheCaracterisation', panel.id]);
          break;
        }
      case TimelineTypesDB.FICHE_REMANENCE:
        {
          this.router.navigate(['volunteers', panel.panelistNumber, panel.hub, 'ficheRemanence', panel.id]);
          break;
        }
      case TimelineTypesDB.FICHE_ENTRETIEN:
        {
          this.router.navigate(['volunteers', panel.panelistNumber, panel.hub, 'ficheEntretien', panel.id]);
          break;
        }
      case TimelineTypesDB.STUDY:
      case TimelineTypesDB.VISIT:
        {
          this.router.navigate(['campaigns', panel.studyId, 'edit', 'rawdata']);
          break;
        }
    }
  }

  goToPanelist(panelist: Panelist): void {
    this.router.navigate(['volunteers', panelist.panelistNumber, panelist.hub, 'generalInformation']);
  }

  goToGroupPanelistHA(data: any): void {
    this.editGroupHorsArcsRowComponent.getGroupPanelistInfo(data.groupName);
  }

  initFilter() {
    this.filter = this.filterService.resetFilterPanelists();
    this.filter.hub = [this.userService.getUserHub(this.user.hub)];
    this.panelistsData = [];
  }

  initFilterHA() {
    this.filterHA = this.filterService.resetFilterPanelists();
    this.filterHA.hub = [this.userService.getUserHub(this.user.hub)];
    this.groupPanelistsHAData = [];
  }

  initArcsFilter() {
    this.filterArcs = this.filterService.resetFilterPanelists();
    this.filterArcs.hub = [this.userService.getUserHub(this.user.hub)];
    this.panelistsArcsData = [];
  }

  onPanelistsParametersChanged(object: any) {
    _.set(this.filter, 'page', _.get(object.pager, 'currentPage', 1));
    _.set(this.filter, 'limit', _.get(object.pager, 'pageSize', 10));
    this.getPanelistsWithFilter(this.filter).subscribe();
  }

  onGroupPanelistsHAParametersChanged(object: any) {
    _.set(this.filterHA, 'page', _.get(object.pager, 'currentPage', 1));
    _.set(this.filterHA, 'limit', _.get(object.pager, 'pageSize', 10));
    this.getGroupPanelistsHAWithFilter(this.filterHA).subscribe();
  }

  onPanelistsArcsParametersChanged(object: any) {
    _.set(this.filterArcs, 'page', _.get(object.pager, 'currentPage', 1));
    _.set(this.filterArcs, 'limit', _.get(object.pager, 'pageSize', 10));
    this.getPanelistsArcsWithFilter(this.filterArcs).subscribe();
  }

  searchPanelist() {
    _.set(this.filter, 'page', 1);
    this.getPanelistsWithFilter(this.filter).subscribe();
  }

  searchGroupPanelistHA() {
    this.getGroupPanelistsHAWithFilter(this.filterHA).subscribe();
  }

  searchPanelistArcs() {
    this.getPanelistsArcsWithFilter(this.filterArcs).subscribe();
  }

  setArcsSystemFromCountry(country:Hub) {
    this.country = country;
    this.hub = this.referenceTypeService.getHubByIdFromHub(country);
  }

  setArcsSystemFromCountryHA(country:Hub, hub:HubById, groupVolunteer:string[], formHorsArcsGroup:string, groupVolunteers:string[]) {
    this.countryHA = country;
    this.hubHA = this.referenceTypeService.getHubByIdFromHub(country);

    this.getValuesForFormHA(hub, groupVolunteer, formHorsArcsGroup, groupVolunteers);
    console.log(groupVolunteers)
  }

  sort(volunteers: Volunteers[], headerId: string, reverse: boolean) {
    return this.utilService.sortListByType(volunteers, 'nameFirstName', reverse);
  }

  sortPanelist = (headerId: string, reverse: boolean) => {
    switch (headerId) {
      case 'TYPE':
        _.set(this.filter, 'orderBy', `subclass`);
        break;
      case 'ID_VOLUNTEER':
        _.set(this.filter, 'orderBy', `panelistNumber`);
        break;
      case 'FIRST_NAME':
        _.set(this.filter, 'orderBy', `firstName`);
        break;
      case 'LAST_NAME':
        _.set(this.filter, 'orderBy', 'lastName');
        break;
      case 'ORCHESTRA_NUMBER':
        _.set(this.filter, 'orderBy', 'orchestra');
        break;
      case 'PROJECTS.PROJECT_BRIDGE':
        _.set(this.filter, 'orderBy', 'requestNumber');
        break;
      case 'LABORATORY':
        _.set(this.filter, 'orderBy', 'laboratory');
        break;
      case 'DATE_ENTRY':
        _.set(this.filter, 'orderBy', 'created_on');
        break;
      default:
        _.set(this.filter, 'orderBy', 'created_on');
        break;
    }
    reverse ? _.set(this.filter, 'order', 'ASC') : _.set(this.filter, 'order', 'DESC');
    this.getPanelistsWithFilter(this.filter).subscribe();
  }

  sortGroupPanelistHA = (headerId: string, reverse: boolean) => {
    switch (headerId) {
      case 'NAME':
        _.set(this.filterHA, 'orderBy', `groupName`);
        break;
      case 'NUMBER_OF_VOLUNTEERS':
        _.set(this.filterHA, 'orderBy', `numberOfPanelists`);
        break;
      case 'HUB':
        _.set(this.filterHA, 'orderBy', `hub`);
        break;
      case 'DATE_ENTRY':
        _.set(this.filterHA, 'orderBy', 'created_on');
        break;
      default:
        _.set(this.filterHA, 'orderBy', 'created_on');
        break;
    }
    reverse ? _.set(this.filterHA, 'order', 'ASC') : _.set(this.filterHA, 'order', 'DESC');
    this.getGroupPanelistsHAWithFilter(this.filterHA).subscribe();
  }

  updateDate(text: any, type: string) {
    if (text) this.filter[type] = text;
  }

  updateDateHA(text: any, type: string) {
    if (text) this.filterHA[type] = text;
  }

  getPanelistsWithFilter(ft) {
    this.showSpinner = true;
    let filter = _.cloneDeep(ft);
    filter.hub = filter.hub.map(hub => this.referenceTypeService.getHubByIdFromHub(hub));
    if (!_.isNil(filter.startDate) && filter.startDate !== 0) filter.startDate = filter.startDate.getTime();
    if (!_.isNil(filter.endDate) && filter.endDate !== 0) filter.endDate = filter.endDate.getTime();
    return this.volunteerService.getPanelists(filter).pipe(
      tap((result: Result) => {
        if(!result.list.length && !!filter.studyNumber) {
          this.errorManager.displayMessage('INVALID_STUDYNUMBER', 'danger');
        }
        this.panelistsData = result.list;
        this.totalPanelists = result.totalItems;
      }),
      finalize(() => this.showSpinner = false)
    );
  }

  getPanelistsArcsWithFilter(ft) {
    this.showSpinner = true;
    let filter = _.cloneDeep(ft);
    filter.hub = filter.hub.map(hub => this.referenceTypeService.getHubByIdFromHub(hub));
    return this.volunteerService.getPanelistsArcs(filter).pipe(
      tap((result: any) => {
        this.isPanelistsArcsDataEmpty = (result.list.length === 0);
        this.panelistsArcsData = result.list;
        if (!result.studyPanelistAssociation.isEmpty)
        {
          for (const panelist of this.panelistsArcsData)
          {
            const studyPanelist = result.studyPanelistAssociation.find((study) => study.panelistID === Number(panelist.panelistNumber));
            if (studyPanelist) {
              panelist.studyNumber = studyPanelist.studyNumber;
              panelist.studyName = studyPanelist.studyName;
            }
          }
        }
        this.totalArcsPanelists = result.totalItems;
      }),
      finalize(() => this.showSpinner = false)
    );
  }

  getGroupPanelistsHAWithFilter(ft) {
    this.showSpinner = true;
    let filter = _.cloneDeep(ft);
    filter.hub = filter.hub.map(hub => this.referenceTypeService.getHubByIdFromHub(hub));
    if (!_.isNil(filter.startDate) && filter.startDate !== 0) filter.startDate = filter.startDate.getTime();
    if (!_.isNil(filter.endDate) && filter.endDate !== 0) filter.endDate = filter.endDate.getTime();
    return this.volunteerService.getVolunteerGroup(filter).pipe(
      tap((result: Result) => {
        this.groupPanelistsHAData = result.list;
        this.totalPanelistsHA = result.totalItems;
      }),
      finalize(() => this.showSpinner = false)
    );
  }

  openModalGroupeVolunteer() {
    const modal = this.modalService.open(ModalGroupVolunteersComponent, this.modalOption);
    // modal.componentInstance.id = "";
    modal.componentInstance.isAssociateStudyPossible = true;
    modal.componentInstance.isUserAdmin = this.user.roles.includes('DNA_ADMIN');
    modal.result.then(
      (data) => {
        this.showSpinner = false;
        if (!this.groupVolunteersHA.includes(data)) {
          this.groupVolunteersHA.unshift(data)
          this.editGroupHorsArcsRowComponent.groupVolunteers.unshift(data)
        }
      },
      (reason) => { }
    );
  }


}
