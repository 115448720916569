  <ngx-loading [show]="showSpinner">
</ngx-loading>
<div id="wrapper" class="top-navigation">
  <div id="page-wrapper" class="gray-bg">
    <div class="row border-bottom white-bg">
      <nav class="navbar navbar-expand-md pt-0 pb-0 navbar-fixed-top">
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fa fa-reorder"></i>
        </button>
        <a class="navbar-brand pt-2 pb-2" routerLink="/dashboard">
          <img src="./assets/logo-horizontal.svg" alt="LogoHorizontal" />
        </a>
        <div id="navbarSupportedContent" class="collapse navbar-collapse justify-content-end">
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <a class="nav-link pt-0 pb-0" (click)="goToPerformanceCenter()" translate> <img src="./assets/pc_icon.svg" alt="pc icon" width="20"/>PERFORMANCE_CENTER</a>
            </li>
            <!-- <li class="nav-item">
              <a *ngIf="isAuthorized('DNA_SCHEDULE')" class="nav-link pt-0 pb-0" routerLink="/trainings"
                translate>TRAININGS</a>
            </li> -->
            <li class="nav-item" hidden="true">
              <a *ngIf="isAuthorized('DNA_SCHEDULE')" class="nav-link pt-0 pb-0" routerLink="/multi-campaigns"
                translate>MULTI_CAMPAIGNS</a>
            </li>
            <li class="nav-item">
              <a *ngIf="isAuthorized('DNA_SCHEDULE')" class="nav-link pt-0 pb-0" (click)="navigateToCampaignWithFilterReset()"
                translate>CAMPAIGNS</a>
            </li>
            <li class="nav-item">
              <a *ngIf="isAuthorized('DNA_EXPORT')" class="nav-link pt-0 pb-0" routerLink="/exportDataSet"
                translate>EXPORT</a>
            </li>
            <li class="nav-item" ngbDropdown>
              <a id="library" class="nav-link pt-0 pb-0" role="button" ngbDropdownToggle translate>LIBRARY</a>
              <div class="mt-2 ml-2 rounded-0" role="menu" aria-labelledby="library" ngbDropdownMenu>
                <a *ngIf="isAuthorized('DNA_WORKFLOW')" class="dropdown-item" routerLink="/workflows"
                  translate>WORKFLOWS</a>
                <a *ngIf="isAuthorized('DNA_BLOCK')" class="dropdown-item" routerLink="/blocks" translate>BLOCKS</a>
                <a *ngIf="isAuthorized('DNA_SCHEDULE')" class="dropdown-item" routerLink="/volunteers"
                  translate>VOLUNTEERS</a>
              </div>
            </li>
            <li class="nav-item" ngbDropdown>
              <a id="languages" class="nav-link pt-0 pb-0" role="button" ngbDropdownToggle
                translate>{{ currentLanguage }}</a>
              <div class="mt-2 ml-2 rounded-0" role="menu" aria-labelledby="languages" ngbDropdownMenu>
                <a *ngFor="let language of languages" class="dropdown-item" style="background-color: 'red"
                  [ngClass]="{'active' : currentLanguage == language}" (click)="changeLanguage(language)"
                  translate>{{ language }}</a>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link pt-0 pb-0" routerLink="/myprofile" translate>MY_PROFILE</a>
            </li>
            <li class="nav-item">
              <a class="nav-link pt-0 pb-0" (click)="logout()">
                <i class="fa fa-power-off"></i>{{ 'LOG_OUT' | translate }}</a>
            </li>
            <li *ngIf="user" class="nav-item" ngbDropdown>
              <a id="workspaces" class="nav-link pt-0 pb-0" role="button" ngbDropdownToggle>
                <span translate>WORKSPACE</span> -
                {{ (user.currentWorkspace?.name ? user.currentWorkspace.name : 'ALL') | translate }}
              </a>
              <div *ngIf="shouldBeDisabled()" class="mt-2 ml-2 rounded-0" role="menu" aria-labelledby="workspaces"
                ngbDropdownMenu>
                <a *ngFor="let workspace of user.workspaces" class="dropdown-item"
                  [ngClass]="{'active' : user.currentWorkspace?.name == workspace?.name}"
                  (click)="changeWorkspace(workspace)" translate>{{ workspace.name }}</a>
                <a *ngIf="false" class="dropdown-item"
                  [ngClass]="{'active' : !user.currentWorkspace || !user.currentWorkspace?.name }"
                  (click)="changeWorkspace()" translate>ALL</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="wrapper wrapper-content">
      <div class="header">
        <div id="header-inner" [ngClass]="navItem"></div>
      </div>
      <div class="container">
        <ng-content></ng-content>
        <button id="btn-go-top" type="button" scrollToTop>
          <i class="fa fa-arrow-circle-up fa-5x" aria-hidden="true"> </i>
        </button>
      </div>
      <footer class="fixed-bottom">
        <div class="footer fixed-bottom">
          <div class="pull-right cursor-pointer">
            <small (click)="linkDataPrivacy('TERMS_OF_USE')" translate>TERMS_OF_USE</small> |
            <small (click)="linkDataPrivacy('PRIVACY_POLICY')" translate>PRIVACY_POLICY</small>
          </div>
          <div class="text-center">
            <copyright></copyright>
          </div>
        </div>
        <input type="hidden" id="tourSign" value="">
      </footer>
    </div>
  </div>
</div>
