import { Component, Input, ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { ChartOptionsControlService } from '../../services';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-graph-options',
  templateUrl: './graph-options.component.html',
  styleUrls: ['./graph-options.component.scss'],
  providers: [ ChartOptionsControlService ]
})
export class GraphOptionsComponent implements OnChanges {
  @Input()  parameters : any;
  @Input()  identifier : number;
  @Input()  notDisplayable : boolean;
  @Input()  initialComment: string = '';

  @Output() handleDownload = new EventEmitter<any>();
  @Output() handleExcelDownload = new EventEmitter<any>();

  private closeSubject: Subject<void> = new Subject<void>();
  @Output() saveGraphOptions = new EventEmitter<any>();

  constructor(
    private ref: ChangeDetectorRef,
    private chartOptionsControlService : ChartOptionsControlService
  ) {
  }

  public wrapperState : boolean = false;
  public objectKeys = Object.keys;
  public form: FormGroup;
  public comment: string = '';
  public commentFontSize: number = 11;
  public commentBold: boolean = false;
  private previousFormState: any = {};
  private save: boolean = false;
  private title: string = "";

  public handleOptionsWrapper = ():boolean => {
    this.emitClosingState();
    return this.wrapperState = !this.wrapperState;
  }

  public cancelForm = () => {
    this.form.reset(this.previousFormState);
    this.handleOptionsWrapper();
    this.comment = this.initialComment ? this.initialComment : "";
  }

  public submitForm = () => {
    this.handleOptionsWrapper();
    this.prepareSaveOptions(this.form, this.parameters.options);
    this.setPreviousFormState();
    this.parameters.callback(this.parameters.getParametersFromOptions());
    this.saveChartOptions();
  }

  ngOnChanges(changes : SimpleChanges) {
    this.updateForm(this.parameters && this.parameters.options ? this.parameters.options : {});
    this.ref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.initialComment) {
      this.comment = this.initialComment;
    }
    this.ref.detectChanges();
  }

  public updateForm(options : any) {
    delete this.form;
    this.form = this.chartOptionsControlService.toFormGroup(options);
    this.setPreviousFormState();
  };

  public checkboxUpdate = (event: any, optionLabel: string, element: any) : void => {
    const payload = {};
    payload[element.key] = Boolean(event.target.checked);
    this.form.get(optionLabel).patchValue(payload);
  };

  private prepareSaveOptions = (group : FormGroup, payload : any) : void => {
    Object.keys(group.controls).forEach(elementKey => {
      const optionElement = payload[elementKey];
      optionElement.options.forEach(option => {
        if(option.type === 'checkbox') {
          option.value = JSON.parse(group.get(elementKey)?.get(option.key)?.value ?? 'false');
        }
        else {
          if (option.key  === 'chart_title') {
            this.title = group.get(elementKey).get(option.key).value;
            if (!_.isEqual(option.value, this.title)) {
              this.save = true;
            }
          }
          option.value = group.get(elementKey).get(option.key).value;
        }
      });

    })
    if (!_.isEqual(this.comment, this.initialComment)){
      this.save = true;
    }
  }

  /**
   * Save current from state as previous one for reset purpose
   * @return {void}
   */
  private setPreviousFormState = (): void => {
    this.previousFormState = this.form.getRawValue();
  }

  private emitClosingState = () => {
    this.closeSubject.next();
  }

  private saveChartOptions() {
    this.saveGraphOptions.emit( {
      parameters: this.parameters.options,
      identifier: this.identifier,
      title: this.title,
      comment: this.comment,
      commentSize: this.commentFontSize,
      commentBold: this.commentBold} );
    this.save = false;
  }
}

